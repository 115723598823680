<template>
	<div>
		<div class="row">
	        <div class="col-12">
				<e-select
					id="entity_id"
					v-model="entity_selected"
					track-by="tiers_id"
					label="tiers_rs"
					:placeholder="$t('tiers.rechercher_billing_entity')"
					:selectedLabel="$t('global.selected_label')"
					:options="entity"
					:searchable="true"
					:allow-empty="true"
					:loading="isLoadingEntity"
					:show-labels="false"
					:multiple="true"
				>
					<template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="col-12 mt-2">
				<DateRangeV2
					:start.sync="start"
					:end.sync="end"
					@submit="loadInvoicesSupplier"
					dateSelect
					persistId="InvoicesChecked"
					:periodToShow="['personalise', 'jour', 'semaine_derniere', 'mois_dernier', 'annee_en_cours', 'annee_derniere']"
				/>
			</div>
		</div>

		<CustomTable
            id_table="invoice_suppliers_ocr_checked"
            ref="invoice_suppliers_ocr_checked"
            :items="invoices"
            :busy.sync="table_busy"
            primaryKey="suppliersocr_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
			:externSlotColumns="extern_slot_columns"
			:groupByCustom="customGroupBy"
        >
            <template v-slot:[`custom-slot-cell(suppliersocr_num)`]="{ data }">
                <a href="" class="" @click.prevent="quickPreview(data.suppliersocr_pdf_url)">
				    {{ data.suppliersocr_num }} <font-awesome-icon :icon="['fas', 'eye']" />
                </a>
			</template>
            <template v-slot:[`custom-slot-cell(status)`]="{ data }">
            	{{ $t('invoice.supplier_status.'+data.status) }}
			</template>
            <template v-slot:[`custom-slot-cell(suppliersocr_export_status)`]="{ data }">
            	<span :class="'export_status_'+data.suppliersocr_export_status">{{ $t('invoice.supplier_export_status.'+data.suppliersocr_export_status) }}</span>
			</template>
			<template v-slot:custom-slot-cell(horses)="{ data }">
                <span v-for="horse, index in data.horses" :key="index">
                    <router-link :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                        {{ horse.horse_nom }}
                    </router-link>
                    <span v-if="index != data.horses.length-1">, </span>
                </span>
            </template>
		</CustomTable>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="pdf_url = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="pdf_url != null" :src="pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</div>
</template>

<style type="text/css">
	.export_status_exported {
		color: #28a745;
	}
	.export_status_ready {
		color: orange;
	}
	.export_status_to_prepare {
		color: #dc3545;
	}
</style>

<script type="text/javascript">
	import { EventBus } from "EventBus"
    import Tiers from "@/mixins/Tiers.js"
	import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'CheckedList',
		mixins: [Tiers, Invoice, Navigation, Shutter, ShutterInvoice],
		props: ['tiers_id'],
		data () {
			return {
				entity_selected: null,
				entity: [],
				isLoadingEntity: false,
				invoices: [],
				table_busy: false,
                events_tab: {
                    'TableAction::goToEditInvoiceSupplier': this.setupInvoiceSupplierEdit,
                    'TableAction::goToDeleteInvoiceSupplier': this.goToDeleteInvoiceSupplier,
                    'TableAction::goToAddPaymentSupplier': this.setUpPaymentInvoiceSupplier,
                    'TableAction::goToAddChargeAccountSupplier': this.setupChargeAccountSupplier,
                    'TableAction::goToExportComptableSupplier': this.export_comptable,
                    'TableAction::goToAccountedSupplier': this.accounted,
                },
				extern_slot_columns: ['suppliersocr_num', 'status', 'suppliersocr_export_status', 'horses'],
                config_table_hrefs: {
                    'entity.tiers_rs': {
                        routeUniqueName: 'entityTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'entity.tiers_id'
                        }
                    },
                    'tier.tiers_rs': {
                        routeUniqueName: 'tiersTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'tier.tiers_id'
                        }
                    },
                },
                pdf_url: null,
                start: new Date(new Date().setDate(new Date().getDate() - 60)),
                end: new Date(),
                customGroupBy: {'status': this.getTradStatus},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.isLoadingEntity = true
				const accounting = await this.loadAccountingPlans()
				const accounting_supplier = accounting.filter(acc => acc.accountingplan_supplier)
				this.entity = accounting_supplier.map(acc => acc.tiers)
				let prev_selection = this.getConfig('invoice_suppliers_entities')
				this.entity_selected = prev_selection ? this.entity.filter(en => prev_selection.includes(en.tiers_id)) : _cloneDeep(this.entity)
				this.isLoadingEntity = false
				this.loadInvoicesSupplier()
			},

			async loadInvoicesSupplier() {
				if(!this.start || !this.end || this.isLoadingEntity) {
					return false
				}

				if(this.$refs.invoice_suppliers_ocr_checked) {
					this.$refs.invoice_suppliers_ocr_checked.unselectAll()
				}
				let entity_ids = []
				if(this.entity_selected && this.entity_selected.length !== 0) {
					entity_ids = this.entity_selected.map(tiers => tiers.tiers_id)
					this.setConfig('invoice_suppliers_entities', entity_ids)
				}

				this.table_busy = true
				if(this.tiers_id) {
					this.invoices = await this.getInvoiceSupplierCheckedByTiers(this.tiers_id, entity_ids, this.start.toDateInputValue(), this.end.toDateInputValue())
				}
				else {
					this.invoices = await this.getInvoiceSupplierChecked(entity_ids, this.start.toDateInputValue(), this.end.toDateInputValue())
				}
				this.table_busy = false
			},

			async goToDeleteInvoiceSupplier(invoice) {
				await this.deleteInvoiceSupplier(invoice.suppliersocr_id)
				EventBus.$emit("TableAction::stopSpin")
				this.successToast('toast.info_save_succes')
				this.$refs.invoice_suppliers_ocr_checked.unselectAll()
				this.loadInvoicesSupplier()
			},	

            async quickPreview(pdf_url) {
                this.$refs.modelPreview.show()
                this.pdf_url = pdf_url
            },

            getTradStatus(status) {
            	let all_status = ['partially_paid', 'paid', 'to_pay']
            	return all_status.includes(status) ? this.getTrad('invoice.supplier_status.'+status) : status
            },

            async export_comptable(invoicesupplier_ids) {
                await this.loadExportComptableSupplier(invoicesupplier_ids)
                EventBus.$emit('TableAction::stopSpin')
            },

            async accounted(invoicesupplier_ids) {
                await this.setAccountedInvoiceSupplier(invoicesupplier_ids)
                EventBus.$emit('TableAction::stopSpin')
                this.loadInvoicesSupplier()
            },
		},

		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>